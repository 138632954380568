export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const LOAD_LOGIN_USER_REQUEST = "LOAD_LOGIN_USER_REQUEST";
export const LOAD_LOGIN_USER_SUCCESS = "LOAD_LOGIN_USER_SUCCESS";
export const LOAD_LOGIN_USER_FAIL = "LOAD_LOGIN_USER_FAIL";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";

export const SEND_PASSWORD_REST_EMAIL_REQUEST =
  "SEND_PASSWORD_REST_EMAIL_REQUEST";
export const SEND_PASSWORD_REST_EMAIL_SUCCESS =
  "SEND_PASSWORD_REST_EMAIL_SUCCESS";
export const SEND_PASSWORD_REST_EMAIL_FAIL = "SEND_PASSWORD_REST_EMAIL_FAIL";

export const USER_PASSWORD_REST_REQUEST = "USER_PASSWORD_REST_REQUEST";
export const USER_PASSWORD_REST_SUCCESS = "USER_PASSWORD_REST_SUCCESS";
export const USER_PASSWORD_REST_FAIL = "USER_PASSWORD_REST_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER_ROLE_REQUEST = "UPDATE_USER_ROLE_REQUEST";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
