export const GET_ALL_REVIEWS_REQUEST = "GET_ALL_REVIEWS_REQUEST";
export const GET_ALL_REVIEWS_SUCCESS = "GET_ALL_REVIEWS_SUCCESS";
export const GET_ALL_REVIEWS_FAIL = "GET_ALL_REVIEWS_FAIL";

export const GET_ALL_ADMIN_REVIEWS_REQUEST = "GET_ALL_ADMIN_REVIEWS_REQUEST";
export const GET_ALL_ADMIN_REVIEWS_SUCCESS = "GET_ALL_ADMIN_REVIEWS_SUCCESS";
export const GET_ALL_ADMIN_REVIEWS_FAIL = "GET_ALL_ADMIN_REVIEWS_FAIL";

export const DELETE_REVIEWS_REQUEST = "DELETE_REVIEWS_REQUEST";
export const DELETE_REVIEWS_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS";
export const DELETE_REVIEWS_REVIEWS_FAIL = "DELETE_REVIEWS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
