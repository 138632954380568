export const GET_USER_ORDERS_REQUEST = "GET_USER_ORDERS_REQUEST ";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS ";
export const GET_USER_ORDERS_FAIL = "GET_USER_ORDERS_FAIL ";

export const GET_USER_ORDERS_DETAILS_REQUEST =
  "GET_USER_ORDERS_DETAILS_REQUEST ";
export const GET_USER_ORDERS_DETAILS_SUCCESS =
  "GET_USER_ORDERS_DETAILS_SUCCESS ";
export const GET_USER_ORDERS_DETAILS_FAIL = "GET_USER_ORDERS_DETAILS_FAIL ";

export const GET_ALL_ORDERS_ADMIN_REQUEST = "GET_ALL_ORDERS_ADMIN_REQUEST";
export const GET_ALL_ORDERS_ADMIN_SUCCESS = "GET_ALL_ORDERS_ADMIN_SUCCESS";
export const GET_ALL_ORDERS_ADMIN_FAIL = "GET_ALL_ORDERS_ADMIN_FAIL";

export const UPDATE_ORDER_ADMIN_REQUEST = "UPDATE_ORDER_ADMIN_REQUEST";
export const UPDATE_ORDER_ADMIN_SUCCESS = "UPDATE_ORDER_ADMIN_SUCCESS";
export const UPDATE_ORDER_ADMIN_FAIL = "UPDATE_ORDER_ADMIN_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
